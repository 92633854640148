<template>
  <v-app>
    <v-app-bar
        app
        color="primary"
        dark
    >
      <div class="d-flex align-center">
        <v-img
            alt="Cita Logo"
            class="shrink mr-2"
            contain
            src="/icons/icon-192x192.png"
            transition="scale-transition"
            width="40"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
          href="https://www.citadelle.com/"
          target="_blank"
          text
      >
        <span class="mr-2">La Citadelle</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-row v-if="loading">
        <v-progress-circular size="70" indeterminate color="orange" class="ma-auto"></v-progress-circular>
      </v-row>
      <HelloWorld v-else/>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',
  components: {
    HelloWorld,
  },
  methods: {
    async cachedAssets() {
      const cache_name = "CitaApp";
      const ASSETS = [
        "./img/citamixinternet.jpg",
        "/index.html",
        "/offline.html",
        "/"
      ];
      return new Promise((resolve,reject)=>{
        console.log("Installing custom assets");
        caches
            .open(cache_name)
            .then(cache => {
              return resolve(cache.addAll(ASSETS));
            })
            .catch(err => {
              console.log(err);
              return reject();
            })
            .finally(()=>{
              console.log("... installation finished");
            })
      })
    }
  },
  data: () => ({
    loading: true,
  }),
  created() {
    this.cachedAssets().finally(()=>{
      this.loading=false;
    })
  }
};
</script>
